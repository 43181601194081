import React, { useEffect, useState } from 'react';

import { ReactComponent as MenuIcon } from '../../../../assets/icons/menu.svg';
import VeDilogo from '../../../../assets/images/VeDilogo.png';
import VeDilogoMobile from '../../../../assets/images/VeDilogoMobile.png';
import { isNative, redirectToCiDi } from '../../../../utils/generic-functions/genericFunctions';
import classes from './Toolbar.module.css';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { cidiActivo } from '../../../../services/segundoLoginServices';
import { useAppState } from '../../../../context/AppStateContext';

const Toolbar = props => {
  const { menuIconClicked, openLogin } = props;
  const [shadow, setShadow] = useState([classes.fixed]);
  const [isCidiActivo, setIsCidiActivo] = useState(true);
  const { isLoading, setIsLoading } = useAppState();
  const isMobile = window.innerWidth <= 767;
  const history = useHistory();

  useEffect(() => {
    const startHeaderElement = document.getElementById('startHeader');
    window.addEventListener('scroll', () => {
      const startHeaderPosition = startHeaderElement.getBoundingClientRect().top;
      if (0 > startHeaderPosition) {
        setShadow([classes.fixed, classes.shadow]);
      } else {
        setShadow([classes.fixed]);
      }
    });
  }, []);

  useEffect(() => {
    const CidiActivo = async () => {
      setIsLoading(true);
      try {
        const data = await cidiActivo();
        if (!data.ok) {
          throw new Error(data.error || 'Error procesando la solicitud');
        }
        setIsCidiActivo(data.return);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    CidiActivo();
  }, []);

  const loginHandler = () => {
    isNative().then(response => {
      if (response) {
        openLogin();
      } else {
        redirectToCiDi();
      }
    });
  };

  // const registrateHandler = () => {
  //     registrate()
  // }

  const handleLinkClick = (event, targetId) => {
    event.preventDefault();
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <div className={shadow.join(' ')}>
        {new Date() < new Date('2025-03-22T10:00:00') && (
          <section style={{ backgroundColor: '#f6bd2b', padding: '10px', textAlign: 'center' }}>
            <p>
              Atención! <strong>Mantenimiento Programado</strong>
              <span>: Desde el </span>
              <strong>viernes 21/03, a las 20 hs, hasta el sábado 22/03 a las 10 hs </strong>
              <span>
                aproximadamente, la Plataforma Ciudadano Digital y gran parte de los servicios
                digitales de la Provincia de Córdoba estarán inaccesibles por tareas de mejora y
                mantenimiento.
              </span>
            </p>
          </section>
        )}
        <div className={classes.container}>
          <img className={classes.logo} src={VeDilogo} alt="vedi-logo" />
          <div className={classes.rightLinks}>
            {!isMobile && (
              <>
                <a
                  className={classes.link}
                  href="#que-es"
                  onClick={event => handleLinkClick(event, 'que-es')}
                >
                  ¿Qué es VeDi?
                </a>
                <a
                  className={classes.link}
                  href="#como-accedo"
                  onClick={event => handleLinkClick(event, 'como-accedo')}
                >
                  ¿Cómo accedo?
                </a>
                <a
                  className={classes.link}
                  href="#accesibilidad"
                  onClick={event => handleLinkClick(event, 'accesibilidad')}
                >
                  Accesibilidad
                </a>
              </>
            )}
            {/* <div 
                        className={classes.link}
                        onClick={registrateHandler}> Registrate</div> */}
            <button
              className={[classes.link, classes.button].join(' ')}
              onClick={() => history.push('/segundo-login')}
              style={{ display: isCidiActivo ? 'none' : 'block' }}
            >
              Login Alternativo
            </button>
            <button
              disabled={isLoading}
              className={
                isLoading
                  ? [classes.link, classes.buttonDisabled].join(' ')
                  : [classes.link, classes.button].join(' ')
              }
              onClick={loginHandler}
              style={{ display: !isCidiActivo ? 'none' : 'block' }}
            >
              Ingresar a VeDi
            </button>
          </div>
        </div>

        <div className={classes.containerMobile}>
          {!isMobile && <MenuIcon className={classes.icon} onClick={menuIconClicked} />}
          <img src={VeDilogoMobile} alt="vedi-logo-mobile" />
          <div className={classes.rightLinks}>
            <button
              className={[classes.link, classes.button].join(' ')}
              onClick={() => history.push('/segundo-login')}
              style={{ display: isCidiActivo ? 'none' : 'block' }}
            >
              Login Alternativo
            </button>
            <button
              disabled={isLoading}
              className={
                isLoading
                  ? [classes.link, classes.buttonDisabled].join(' ')
                  : [classes.link, classes.button].join(' ')
              }
              onClick={loginHandler}
              style={{ display: !isCidiActivo ? 'none' : 'block' }}
            >
              Ingresar
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Toolbar;
